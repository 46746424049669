import React from "react";

class Errors extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tieneError: false, mensajeError: "" };
  }

  static getDerivedStateFromError(error) {
    // Método 1
    return { tieneError: true, mensajeError: error.message };
  }

  componentDidCatch(error) {
    // Método 2
    // Ambos sirven por igual
    console.log("Component did catch:", error.message);
  }

  render() {
    if (this.state.tieneError) {
      // "UI de emergencia"
      return (
        <div>
          <p> {this.state.mensajeError} </p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default Errors;
