import { Card, CardMedia } from "@mui/material";
import React, { Fragment } from "react";
import {
  imgPaypal,
  imgMercadoPago,
  imgVisa,
  imgMasterCard,
} from "../../settings/imgConfig";

export default function PagosLogoMetodoPago(props) {
  const { opcionPago } = props;

  return (
    <div>
      {opcionPago === 1 && (
        <img
          src={imgPaypal}
          alt="Metodo Pago"
          style={{ width: "100%", height: "auto", paddingTop: 20 }}
        />
      )}
      {opcionPago === 2 && (
        <img
          src={imgMercadoPago}
          alt="Metodo Pago"
          style={{ width: "100%", height: "auto", paddingTop: 20 }}
        />
      )}
      {opcionPago === 3 && (
        <Fragment>
          <img
            src={imgVisa}
            alt="Metodo Pago"
            style={{ width: "50%", height: "auto" }}
          />
          <br />
          <img
            src={imgMasterCard}
            alt="Metodo Pago"
            style={{ width: "50%", height: "auto" }}
          />
        </Fragment>
      )}
    </div>
  );
}
