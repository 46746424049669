import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { useConfiguration } from "../../context/System/ConfigurationContext";
import {
  routeBase,
  routeFeedback,
  routeCheckOut,
  routeSuccess,
  routeError,
} from "../../settings/routeConfig";
import PagosDashboard from "../pagos/PagosDashboard";
import PagosLoader from "../utils/PagosLoader";
import PagosAlert from "../utils/PagosAlert";
import PagosAppBar from "./PagosAppBar";
import PagosFeedback from "../pagos/PagosFeedback";
import PagosSuccess from "../pagos/PagosSuccess";
import PagosError from "../pagos/PagosError";
import PagosConfirmation from "../pagos/PagosConfirmation";

export default function PagosPage() {
  const { theme } = useConfiguration();
  return (
    <ThemeProvider theme={theme}>
      {/* <PagosAppBar /> */}
      <div className="transporte-dashboard">
        <div className="content">
          <Routes>
            <Route path={routeCheckOut} element={<PagosConfirmation />} />
            <Route path={routeFeedback} element={<PagosFeedback />} />
            <Route path={routeSuccess} element={<PagosSuccess />} />
            <Route path={routeError} element={<PagosError />} />
            <Route path="/*" element={<Navigate to="/checkout" />} />
          </Routes>
        </div>
      </div>
      <PagosAlert />
      <PagosLoader />
    </ThemeProvider>
  );
}
