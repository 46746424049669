export const getModelOrden = () => ({
  IdOrden: null,
  OrdenRef: null,
  Monto: 0,
  Concepto: null,
  Fecha: null,
  OpcionPago: -1,
  Activo: false,
  Pagado: false,
  IdUsuario: null,
  DataEncripted: null,
  IdPaquete: null,
  IdMonedero: null,
  Detalle: [],
});

export const getModelConfigPaypal = () => ({
  clientId: null,
  clientSecret: null,
  baseUrl: null,
  currency: null,
});

export const getModelConfigMercadoPago = () => ({
  accessTokenServer: null,
  accessTokenClient: null,
  currency: null,
  linkSuccess: null,
  linkFailure: null,
});

export const getModelResponseMercadoPago = () => ({
  collection_id: null,
  collection_status: null,
  payment_id: null,
  status: null,
  external_reference: null,
  payment_type: null,
  merchant_order_id: null,
  preference_id: null,
  site_id: null,
  processing_mode: null,
  merchant_account_id: null,
});

export const getModelConfig = () => ({
  ClientId: null,
  ClientSecret: null,
  BaseUrl: null,
  Currency: null,
  AccessTokenServer: null,
  AccessTokenClient: null,
  LinkSuccess: null,
  LinkFailure: null,
});

export const getModelNewOrden = () => ({
  OpcionPago: -1,
  IdUsuario: null,
  Monto: null,
  Concepto: null,
  IdPaquete: null,
});
