import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { getModelResponseMercadoPago } from "../../model/pagosModel";
import { usePagos } from "../../context/PagosContext";
import { routeBase, routeError } from "../../settings/routeConfig";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function PagosFeedback() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { registrarPagoMercadoPago, handleErrorMessage } = usePagos();
  const { i18n, t } = useTranslation()

  useEffect(() => {
    //Definir el lenguaje de nuevo...
    var lang_code = localStorage.getItem("_lang_code");
    i18n.changeLanguage(lang_code.replace(/['"]+/g, ""));

    const orden = JSON.parse(localStorage.getItem("_orden"));
    let response = getModelResponseMercadoPago();

    for (let p of searchParams) {
      if (p[1] !== "null") {
        response[p[0]] = p[1];
      }
    }

    const registrarPago = async (orden, datos) => {
      if (datos.status === "approved") {
        await registrarPagoMercadoPago(orden, datos);
      } else if (datos.status === "rejected") {
        handleErrorMessage(t("pagosFeedback_rechazado"));
        navigate("/" + routeError);
      } else {
        handleErrorMessage(t("pagosFeedback_error"));
        navigate("/" + routeError);
      }
    };

    registrarPago(orden, response);
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography
        variant="h5"
        component="div"
        color="titulo.main"
        sx={{ flexGrow: 1 }}
      >
        Procesando...
      </Typography>
    </Box>
  );
}
