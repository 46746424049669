import { ConfigurationProvider } from "./ConfigurationContext";
import { LayoutProvider } from "./LayoutContext";
import { RequestProvider } from "./RequestContext";
import { LoginProvider } from "./LoginContext";

export const AllSystemProvider = (props) => (
  <ConfigurationProvider>
    <LayoutProvider>
      <LoginProvider>
        <RequestProvider>{props.children}</RequestProvider>
      </LoginProvider>
    </LayoutProvider>
  </ConfigurationProvider>
);
