import { createContext, useContext, useState } from "react";

import { createTheme } from "@mui/material";
import { esES } from "@mui/material/locale";

import { getPaginadoModel } from "../../model/paginadoModel";

export const ConfigurationContext = createContext();

/*const getPaginadoModel = () => ({
  totalDeRegistros:{ value: 0, error: false },
  totalDePaginas:{ value: 0, error: false },
  registroPorPagina:{ value: 2, error: false },
  pagina:{ value: 1, error: false },
});*/

export const ConfigurationProvider = (props) => {
  const [titlePage, setTitlePage] = useState("TRANSPORTE");
  const [paginadoModel, setPaginadoModel] = useState(getPaginadoModel());
  const [theme, setTheme] = useState(
    createTheme({
      palette: {
        primary: {
          main: "#0062AE",
          contrastText: "#FFFFFF",
        },
        secondary: {
          main: "#16216A",
          contrastText: "#FFFFFF",
        },
        green: {
          main: "#78B829",
          contrastText: "#FFFFFF",
        },
        blue: {
          main: "#4fc3f7",
          contrastText: "#FFFFFF",
        },
        red: {
          main: "#ef5350",
          contrastText: "#FFFFFF",
        },
        orange: {
          main: "#ff9800",
          contrastText: "#FFFFFF",
        },
        white: {
          main: "#FFFFFF",
          contrastText: "#FFFFFF",
        },
        titulo: {
          main: "#242f62",
          contrastText: "#FFFFFF",
        },
        subtitulo: {
          main: "#9DA3AE",
          contrastText: "#FFFFFF",
        },
        box: {
          main: "#1D5DA7",
          contrastText: "#FFFFFF",
        },
        box_success: {
          main: "#EEF2F7",
          contrastText: "#FFFFFF",
        },
      },
    })
  );

  const handleChangeConfiguration = (
    urlFont,
    font,
    primaryColor,
    secundaryColor,
    primaryContrast,
    secondaryContrast,
    titlePage,
    metaDescription
  ) => {
    document.getElementById("transporte-title-page").innerHTML = titlePage;
    document
      .getElementById("transporte-meta")
      .setAttribute("content", metaDescription);
    document.getElementById("transporte-urlfont").setAttribute("href", urlFont);
    setTitlePage(titlePage);

    setTheme(
      createTheme(
        {
          typography: {
            fontFamily: font,
          },
          palette: {
            primary: {
              main: primaryColor,
              contrastText: primaryContrast,
            },
            secondary: {
              main: secundaryColor,
              contrastText: secondaryContrast,
            },
            green: {
              main: "#4caf50",
              contrastText: "#FFFFFF",
            },
            blue: {
              main: "#4fc3f7",
              contrastText: "#FFFFFF",
            },
            red: {
              main: "#ef5350",
              contrastText: "#FFFFFF",
            },
            orange: {
              main: "#ff9800",
              contrastText: "#FFFFFF",
            },
          },
          components: {
            MuiButton: {
              defaultProps: {
                variant: "contained",
                color: "primary",
              },
              styleOverrides: {
                root: {
                  boxShadow: "none",
                  borderRadius: 10,
                  textTransform: "initial",
                },
              },
            },
            MuiIconButton: {
              defaultProps: {
                color: "secondary",
              },
              styleOverrides: {
                root: {
                  backgroundColor: "white",
                  ":hover": {
                    backgroundColor: "white",
                  },
                  ":disabled": {
                    backgroundColor: "white",
                  },
                },
              },
            },
            MuiTextField: {
              defaultProps: {
                variant: "outlined",
                color: "secondary",
                fullWidth: true,
                size: "small",
              },
            },
            MuiDialog: {
              defaultProps: {
                fullWidth: true,
                maxWidth: "sm",
              },
            },
            MuiDrawer: {
              defaultProps: {
                BackdropProps: {
                  style: {
                    backgroundColor: "rgba(0, 0, 0, 0.4)",
                  },
                },
              },
            },
            MuiGrid: {
              defaultProps: {
                spacing: 2,
              },
            },
            MuiTable: {
              defaultProps: {
                size: "small",
              },
            },
            MuiTooltip: {
              styleOverrides: {
                tooltip: {
                  fontSize: 14,
                },
              },
              defaultProps: {
                arrow: false,
                placement: "bottom",
              },
            },
            MuiPaper: {
              defaultProps: {
                elevation: 3,
                variant: "elevation",
                //variant: "outlined",
              },
            },
            MuiFormLabel: {
              styleOverrides: {
                asterisk: {
                  color: "#ff8400",
                  fontWeight: "700",
                  fontSize: "18px",
                },
                root: {
                  color: "rgba(0, 0, 0, 0.9)",
                },
              },
            },
            MuiTab: {
              styleOverrides: {
                root: {
                  "&.Mui-selected": {
                    backgroundColor: primaryColor,
                    color: primaryContrast,
                  },
                },
              },
            },
          },
        },
        esES
      )
    );
  };

  const handleChangePaginacionModel = async (e, value) => {
    setPaginadoModel({
      ...paginadoModel,
      [e]: {
        value: value,
        error: false,
      },
    });
  };

  return (
    <ConfigurationContext.Provider
      value={{
        theme,
        titlePage,
        paginadoModel,
        handleChangeConfiguration,
        setPaginadoModel,
        getPaginadoModel,
        handleChangePaginacionModel,
      }}
    >
      {props.children}
    </ConfigurationContext.Provider>
  );
};

export const useConfiguration = () => useContext(ConfigurationContext);
