import { routeBase } from "./routeConfig";

export const imgCheck = "/img/DialogSuccess.png";
export const imgMultiply = "/img/DialogError.png";
export const imgLogo_Banorte = "/img/logo_banorte.png";
export const imgCredit_Card = "/img/credit_card.png";

export const imgVisa = "/img/visa.png";
export const imgMasterCard = "/img/mastercard.png";
export const imgMercadoPago = "/img/mercadopago.png";
export const imgPaypal = "/img/paypal.png";
