import { BrowserRouter } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AllSystemProvider } from "./context/System/AllSystemProvider";
import PagosPage from "./components/layout/PagosPage";
import { routeBase } from "./settings/routeConfig";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { PagosProvider } from "./context/PagosContext";
import "./i18n.ts"

function App() {
  return (
    <BrowserRouter basename={routeBase}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <AllSystemProvider>
          <PagosProvider>
            <PagosPage />
          </PagosProvider>
        </AllSystemProvider>
      </LocalizationProvider>
    </BrowserRouter>
  );
}

export default App;
