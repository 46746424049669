import React, { useEffect, useState } from "react";
import { usePagos } from "../../context/PagosContext";
import { Grid } from "@mui/material";
import { initMercadoPago, Wallet } from "@mercadopago/sdk-react";
import { useTranslation } from "react-i18next";

export default function ButtonMercadoPago(props) {
  const { i18n, t } = useTranslation();
  const { orden } = props;
  const { parametros } = usePagos();
  const preferenceId = orden.OrdenRef;

  useEffect(() => {
    initMercadoPago(parametros.AccessTokenClient, {
      locale: i18n.language == "es" ? "es-MX" : "en-US",
    });
  }, []);

  const customization = {
    texts: {
      action: "pay",
      valueProp: "security_safety",
    },
  };

  const handleSubmit = () => {
    const response = 0;
    if (
      window.AndroidNative &&
      typeof window.AndroidNative.responsePay === "function"
    ) {
      window.AndroidNative.responsePay(JSON.stringify(response));
      console.log("🚀 ~ handleSubmit ~ response:", response, "Android");
    } else if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.messageHandler
    ) {
      window.webkit.messageHandlers.messageHandler.postMessage(
        JSON.stringify(response)
      );
      console.log("🚀 ~ handleSubmit ~ response:", response, "iOS");
    } else {
      console.log("🚀 ~ handleSubmit ~ response:", response, "web");
    }
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyItems="center"
    >
      <Grid item>
        {preferenceId !== null && (
          <Wallet
            initialization={{ preferenceId, redirectMode: "modal" }}
            customization={customization}
            onSubmit={handleSubmit}
          />
        )}
      </Grid>
    </Grid>
  );
}
