import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { usePagos } from "../../context/PagosContext";
import {
  Button,
  Grid,
  Alert,
  AlertTitle,
  LinearProgress,
  Box,
  Collapse,
} from "@mui/material";
import {
  routeBase,
  routeError,
  routeSuccess,
} from "../../settings/routeConfig";
import Errors from "../utils/Errors";
import { useTranslation } from "react-i18next";

const style = {
  layout: "horizontal",
  color: "blue",
  shape: "rect",
  label: "paypal",
  height: 35,
};

export default function ButtonPayPal(props) {
  const { orden } = props;
  const navigate = useNavigate();
  const { parametros, handleAlert, registrarPagoPayPal, handleErrorMessage } =
    usePagos();
  const [initialOptions, setInitialOptions] = useState({
    clientId: null,
    currency: null,
    intent: null,
  });
  const { i18n, t } = useTranslation()

  useEffect(() => {
    if (parametros.clientId !== null) {
      setInitialOptions({
        clientId: parametros.ClientId,
        currency: parametros.Currency,
        intent: "capture",
      });
    }
  }, [parametros]);

  const [open, setOpen] = useState(false);

  const createOrder = () => {
    return orden.OrdenRef;
  };

  const onApprove = async (data, actions) => {
    return actions.order
      .capture()
      .then((details) => {
        var response = false;
        if (details.status == "COMPLETED") {
          response = true;

          //Registrar el pago
          registrarPagoPayPal(orden, details);
        } else {
          handleErrorMessage(
            t("paypal_mensajeError")
          );
          navigate("/" + routeError);
        }
      })
      .catch((err) => {
        handleErrorMessage(t("paypal_mensajeError02"));
        navigate("/" + routeError);
      });
  };

  const onError = (err) => {
    console.log("🚀 ~ file: ButtonPayPal.jsx:65 ~ onError ~ err:", err);
    handleErrorMessage(
      t("paypal_mensajeError")
    );
    navigate("/" + routeError);
  };

  const onCancel = () => {
    handleErrorMessage(t("paypal_onCancel"));
    navigate("/" + routeError);
  };

  // const onClick = async (data, actions) => {
  //   return new Promise((resolve) => setTimeout(resolve, 500)).then(() => {
  //     setOpen(true);
  //     return actions.reject();
  //   });
  // };

  const ButtonWrapper = ({ showSpinner }) => {
    const [{ isPending }] = usePayPalScriptReducer();

    return (
      <>
        {showSpinner && isPending && <LinearProgress />}
        <PayPalButtons
          style={style}
          disabled={false}
          forceReRender={[style]}
          fundingSource={undefined}
          createOrder={createOrder}
          onApprove={(data, actions) => {
            onApprove(data, actions);
          }}
          onCancel={onCancel}
          onError={onError}
        />
      </>
    );
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
    >
      <Grid item>
        {initialOptions.clientId != null && (
          <PayPalScriptProvider options={initialOptions}>
            <ButtonWrapper showSpinner={true} />
          </PayPalScriptProvider>
        )}
      </Grid>
      <Grid item>
        <Box
          sx={{
            width: "100%",
            //visibility: !open ? "hidden" : "visible",
          }}
        >
          <Alert severity="info">
            <AlertTitle>{t("paypal_tituloAlert")}</AlertTitle>
            {t("paypal_mensajeAlert")}
          </Alert>
        </Box>
      </Grid>
    </Grid>
  );
}
