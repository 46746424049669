export const getUser = () => ({
  sUserName: "nrodriguez",
  sPassword: "D29r8rTcXK5YEkDF",
});

export const getUserApp = () => ({
  // Local
  // Username: "juan.perez@correo.com",
  // Password: "JuanPerez01",
  // GrantType: "password",
  // FcmToken: "slslsl-dkdtys-ye54ega",
  // QA
  GrantType: "password",
  FcmToken: "slslsl-dkdtys-ye54ega",
  Password: "@dmin.QA",
  Username: "nrodriguez@imasd.com.mx",
});
