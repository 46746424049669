import React, { useEffect } from "react";
import { Box, Grid, Typography, Container, Button } from "@mui/material";
import { imgMultiply } from "../../settings/imgConfig";
import { usePagos } from "../../context/PagosContext";
import { useTranslation } from "react-i18next";

export default function PagosError() {
  const orden = JSON.parse(localStorage.getItem("_orden"));
  const { errorMessage, handleClickFinalizar } = usePagos();
  const { i18n, t } = useTranslation();

  return (
    <Container sx={{ p: 0, m: 0 }}>
      <Box
        sx={{
          backgroundColor: "box_success.main",
          flexGrow: 1,
          maxHeight: "40vh",
        }}
      >
        <Grid
          item
          component="div"
          xs={12}
          sx={{
            pt: 3,
            pb: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={imgMultiply}
            alt="Check"
            style={{ width: 40, alignSelf: "center" }}
          />
        </Grid>
        <Grid item xs={12} sx={{ pb: 3 }}>
          <Typography variant="h6" align="center" color="red.main">
            {t("pagosError_titulo")}
          </Typography>
        </Grid>
      </Box>
      <Box>
        <Grid item xs={12} sx={{ pt: 3, pl: 3, pr: 3, minHeight: "30vh" }}>
          <Typography
            variant="button"
            component="div"
            color="subtitulo.main"
            align="center"
          >
            {t("pagosError_descripcion")}
          </Typography>
          <Typography variant="button" component="div" align="center">
            {errorMessage}
          </Typography>
        </Grid>
      </Box>
      <Box>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-end"
          minHeight="20vh"
        >
          <Grid item>
            <Button
              variant="contained"
              size="large"
              sx={{
                width: "70vw",
                background: "#00A7E5",
                textTransform: "initial",
              }}
              onClick={() => {
                handleClickFinalizar("ERROR");
              }}
            >
              {t("pagosError_button")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
