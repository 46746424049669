import { createContext, useContext, useState } from "react";
import axios from "axios";
import { useLayout } from "./LayoutContext";
import { apiLogin } from "../../settings/apiConfig";
import { getUserApp } from "../../settings/userConfig";
import { useTranslation } from "react-i18next";

export const LoginContext = createContext();

export const LoginProvider = (props) => {
  const { handleOpenAlert, handleOpenLoader, handleCloseLoader } = useLayout();
  const [token, setToken] = useState(null);
  const { i18n, t } = useTranslation();

  const errorMessage = "Ocurrió un error al consumir el recurso solicitado";

  const login = async () => {
    let isLogin = false;
    try {
      handleOpenLoader(t("context_loadingOrden"));

      const body = getUserApp();

      await axios
        .patch(apiLogin, body)
        .then((resp) => {
          localStorage.setItem("_token", resp.data.Result.Token.AccessToken);
          isLogin = true;
        })
        .catch((error) => {
          handleOpenAlert(t("login_error"));
        });
    } catch (error) {
      handleOpenAlert(errorMessage);
    } finally {
      handleCloseLoader();
    }

    return isLogin;
  };

  return (
    <LoginContext.Provider
      value={{
        token,
        login,
      }}
    >
      {props.children}
    </LoginContext.Provider>
  );
};

export const useLogin = () => useContext(LoginContext);
