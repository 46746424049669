import React, { Fragment } from "react";

import { Box, Grid, Typography, Divider, Button } from "@mui/material";

import { useLogin } from "../../context/System/LoginContext";
import { usePagos } from "../../context/PagosContext";
import { useTranslation } from "react-i18next";

import ButtonPayPal from "../payPal/ButtonPayPal";
import ButtonMercadoPago from "../mercadoPago/ButtonMercadoPago";
import ButtonBanorte from "../Banorte/ButtonBanorte";
import PagosLogoMetodoPago from "../layout/PagosLogoMetodoPago";

export default function PagosConfirmation() {
  const {
    orden,
    crearOrden,
    parametros,
    formatMoneda,
    getOrden,
    handleAlert,
    handleRedirect,
    sumarItems,
    handleClickFinalizar,
  } = usePagos();
  const { login } = useLogin();
  const { i18n, t } = useTranslation();

  window.createOrder = async (token, newOrdenJson, lang_code = "es") => {
    //Definir el lenguaje de la pagina.
    i18n.changeLanguage(lang_code);
    localStorage.setItem("_lang_code", JSON.stringify(lang_code));

    const _newOrden = JSON.parse(newOrdenJson);

    await crearOrden(token, _newOrden);
  };

  window.getOrder = async (idOrden) => {
    let isLogin = await login();
    if (isLogin) {
      await getOrden(idOrden);
    } else {
      handleAlert("Error al autenticarse.");
    }
  };

  window.redirectAfterPayBanorte = async (opcion, response) => {
    handleRedirect(opcion, response);
  };

  return (
    <Box sx={{ flexGrow: 1, pt: 1, pl: 3, pr: 3 }}>
      <Grid container>
        <Grid item textAlign="center" xs={12} sx={{ p: 1 }}>
          <Typography variant="h5" component="div" color="titulo.main">
            {t("pagosConfirmation_titulo")}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h5" color="subtitulo.main">
            {t("pagosConfirmation_metodoPago")}
          </Typography>
          <Typography variant="body1" color="subtitulo.main" sx={{ pt: 1 }}>
            {orden.OpcionPago === 1 && "Paypal"}
            {orden.OpcionPago === 2 && "Mercado Pago"}
            {orden.OpcionPago === 3 && t("banorte_boton")}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <PagosLogoMetodoPago opcionPago={orden.OpcionPago} />
        </Grid>
        <Grid item xs={12} sx={{ pt: 1 }}>
          <Divider />
        </Grid>
        <Grid item xs={12} sx={{ pt: 4 }}>
          <Typography variant="body1" color="subtitulo.main" sx={{ pt: 1 }}>
            {orden.Concepto}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ pt: 1 }}>
          <Typography variant="button" color="subtitulo.main">
            <b>{formatMoneda(orden.Monto, true)}</b>
          </Typography>
        </Grid>
        <Grid item xs={8} sx={{ pt: 8 }}>
          <Typography variant="body2" color="subtitulo.main" component="div">
            Subtotal
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ pt: 8 }}>
          <Typography
            align="right"
            variant="body2"
            component="div"
            color="subtitulo.main"
          >
            {formatMoneda(orden.Monto)}
          </Typography>
        </Grid>
        {orden.Detalle.find((item) => item.Comision === true) && (
          <Fragment>
            <Grid item xs={8} sx={{ pt: 1 }}>
              <Typography
                variant="body2"
                component="div"
                color="subtitulo.main"
              >
                {t("pagosConfirmation_Comision")}
              </Typography>
            </Grid>
            <Grid item xs={4} sx={{ pt: 1 }}>
              <Typography
                align="right"
                variant="body2"
                component="div"
                color="subtitulo.main"
              >
                {formatMoneda(
                  orden.Detalle.find((item) => item.Comision === true).Monto
                )}
              </Typography>
            </Grid>
          </Fragment>
        )}
        <Grid item xs={12} sx={{ pt: 3 }}>
          <Divider />
        </Grid>
        <Grid item xs={8} sx={{ pt: 3 }}>
          <Typography variant="body1" component="div">
            Total
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ pt: 3 }}>
          <Typography align="right" variant="body1" component="div">
            {orden.Detalle && (
              <b>
                {formatMoneda(
                  orden.Detalle.reduce((prev, det) => prev + det.Monto, 0),
                  true
                )}
              </b>
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="caption"
          component="div"
          color="subtitulo.main"
          sx={{ pt: 3, pl: 3, pr: 3 }}
        >
          <i>{t("pagosConfirmation_Leyenda")}</i>
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ pt: 3 }}>
        {orden.OpcionPago === 1 && <ButtonPayPal orden={orden} />}
        {orden.OpcionPago === 2 && parametros.AccessTokenClient !== null ? (
          <ButtonMercadoPago orden={orden} />
        ) : (
          <></>
        )}
        {orden.OpcionPago === 3 && <ButtonBanorte />}
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          onClick={() => handleClickFinalizar("CANCEL")}
          style={{
            color: "#9DA3AE",
            textTransform: "initial",
          }}
        >
          Cancelar
        </Button>
      </Grid>
    </Box>
  );
}
