import React, { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { usePagos } from "../../context/PagosContext";
import { useTranslation } from "react-i18next";

export default function ButtonBanorte() {
  const { orden, handleClickBanorte } = usePagos();
  const { i18n, t } = useTranslation();
  return (
    <div>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Button
            variant="contained"
            size="large"
            sx={{ width: "70vw", background: "#00A7E5" }}
            startIcon={<CreditCardIcon />}
            onClick={handleClickBanorte}
          >
            {t("banorte_boton")}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
