//export const serverPagos = "https://localhost:7081/";
//export const serverPagos = "http://app-service/";

export const serverPagos = "https://api.qa-transporte.mrptechnology.mx/";

//#region Login
export const apiLogin = serverPagos + "api-movil/authentication";
//#endregion

//#region Pagos
export const apiCrearOrden = serverPagos + "api-movil/pagos/ordenes";
export const apiOrdenObtener =
  serverPagos + "api-movil/pagos/ordenes?idOrden={idOrden}";
export const apiParametrosObtener =
  serverPagos + "api-movil/pagos/config/{iOpcionPago}";
export const apiRegistrarPagoPaypal =
  serverPagos + "api-movil/pagos/paypal/registrar-pago";
export const apiRegistrarPagoMercadoPago =
  serverPagos + "api-movil/pagos/mercadopago/registrar-pago";
export const apiRegistrarPagoBanorte =
  serverPagos + "api-movil/pagos/banorte/registrar-pago";
//#endregion
