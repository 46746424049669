import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  lng: "es",
  fallbackLng: "es",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: {
        //Dashboard
        pagosDashboard_titulo: "Payment confirmation",
        pagosDashboard_concepto: "Description",
        pagosDashboard_monto: "Amount",
        pagosDashboard_montoTotal: "TOTAL AMOUNT",
        //ButtonBanorte
        banorte_boton: "Credit / Debit",
        //ButtonPayPal
        paypal_tituloAlert: "Info.",
        paypal_mensajeAlert:
          "After entering the payment details, wait for its confirmation.",
        paypal_mensajeError:
          "Your payment could not be processed. Please try generating a new order.",
        paypal_mensajeError02: "Your payment could not be processed.",
        paypal_onCancel: "The payment process has been cancelled.",
        //PagosError
        pagosError_titulo: "Payment failed",
        pagosError_descripcion: "Details",
        pagosError_button: "Finish",
        //PagosSuccess
        pagosSuccess_titulo: "Payment successful",
        pagosSuccess_concepto: "Description",
        pagosSuccess_button: "Finish",
        //Context
        context_loadingOrden: "Consulting the order",
        context_loadingCrearOrden: "Creating the order",
        context_loadingProcesandoPago: "Processing payment",
        context_onCloseBanorte: "The modal window has been closed.",
        context_onErrorBanorte: "Your payment could not be processed.",
        context_onCancelBanorte: "The payment process has been cancelled.",
        //Login
        login_loading: "Signing in",
        login_error: "An error occurred while authenticating.",
        //PagosFeedback
        pagosFeedback_rechazado: "Your payment was rejected.",
        pagosFeedback_error: "Your payment could not be processed.",
        //PagosConfirmation
        pagosConfirmation_titulo: "Abstract",
        pagosConfirmation_metodoPago: "Payment Method",
        pagosConfirmation_metodoPago_subTitulo: "Debit card / credit",
        pagosConfirmation_Comision: "Commission",
        pagosConfirmation_Leyenda:
          "By continuing, you will be redirected to the selected payment processor.",
      },
    },
    es: {
      translation: {
        //Dashboard
        pagosDashboard_titulo: "Confirmación del pago",
        pagosDashboard_concepto: "Concepto",
        pagosDashboard_monto: "Monto",
        pagosDashboard_montoTotal: "MONTO TOTAL",
        //ButtonBanorte
        banorte_boton: "Crédito / Débito",
        //ButtonPayPal
        paypal_tituloAlert: "Información",
        paypal_mensajeAlert:
          "Después de ingresar los datos del pago, espere hasta la confirmación del mismo.",
        paypal_mensajeError:
          "No se pudo procesar el pago, intente generando una nueva orden.",
        paypal_mensajeError02: "No se pudo procesar el pago.",
        paypal_onCancel: "El pago se ha cancelado.",
        //PagosError
        pagosError_titulo: "Error al procesar el pago",
        pagosError_descripcion: "Detalles",
        pagosError_button: "Finalizar",
        //PagosSuccess
        pagosSuccess_titulo: "Pago exitoso",
        pagosSuccess_concepto: "Concepto",
        pagosSuccess_button: "Finalizar",
        //Context
        context_loadingOrden: "Consultando la orden",
        context_loadingCrearOrden: "Creando la orden",
        context_loadingProcesandoPago: "Registrando pago",
        context_onCloseBanorte: "La ventana modal se ha cerrado.",
        context_onErrorBanorte: "Ha ocurrido un error al procesar el pago.",
        context_onCancelBanorte: "Se ha cancelado el proceso del pago.",
        //Login
        login_loading: "Iniciando sesión",
        login_error: "Ocurrió un error al autentificarse.",
        //PagosFeedback
        pagosFeedback_rechazado: "El pago ha sido rechazado.",
        pagosFeedback_error: "Su pago no pudo ser procesado.",
        //PagosConfirmation
        pagosConfirmation_titulo: "Resumen",
        pagosConfirmation_metodoPago: "Método de pago",
        pagosConfirmation_metodoPago_subTitulo: "Tarjeta de débito / crédito",
        pagosConfirmation_Comision: "Comisión",
        pagosConfirmation_Leyenda:
          "Al continuar, serás redirigido al motor de pagos seleccionado.",
      },
    },
  },
});

export default i18n;
