import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRequest } from "./System/RequestContext";
import { useLayout } from "./System/LayoutContext";
import { useLogin } from "./System/LoginContext";
import {
  apiOrdenObtener,
  apiParametrosObtener,
  apiRegistrarPagoPaypal,
  apiRegistrarPagoMercadoPago,
  apiCrearOrden,
  apiRegistrarPagoBanorte,
} from "../settings/apiConfig";
import {
  getModelOrden,
  getModelConfig,
  getModelNewOrden,
} from "../model/pagosModel";
import {
  routeCheckOut,
  routeError,
  routeSuccess,
} from "../settings/routeConfig";
import { useTranslation } from "react-i18next";

export const PagosContext = createContext();

export const PagosProvider = (props) => {
  const navigate = useNavigate();
  const { GetRequest, PostRequest } = useRequest();
  const { handleOpenAlert } = useLayout();
  const { login } = useLogin();

  const [orden, setOrden] = useState(getModelOrden());
  const [parametros, setParametros] = useState(getModelConfig());
  const [errorMessage, setErrorMessage] = useState(null);
  const { i18n, t } = useTranslation();
  const _language = i18n.language;

  const handleAlert = handleOpenAlert;

  const handleErrorMessage = (error) => {
    setErrorMessage(error);
  };

  const getOrden = async (uIdOrden) => {
    const _url = apiOrdenObtener.replace("{idOrden}", uIdOrden);
    const payload = {
      url: _url,
      loader: t("context_loadingOrden"),
    };

    const response = await GetRequest(payload);
    if (response.HttpCode === 200) {
      setOrden(response.Result);
      console.log("🚀 ~ getOrden ~ Result:", response.Result);
      localStorage.setItem("_orden", JSON.stringify(response.Result));
      //Obtener los parametros de configuracion...
      if (
        response.Result.OpcionPago === 1 ||
        response.Result.OpcionPago === 2
      ) {
        await getParametros(response.Result.OpcionPago);
      }
    } else {
      handleErrorMessage(response.Message);
      navigate("/" + routeError);
    }
  };

  const sumarItems = (detalle) => {
    let reduce = detalle.reduce(
      (acumulador, actual) => acumulador + actual.Monto,
      0
    );
    return formatMoneda(reduce, true);
  };

  const crearOrden = async (token, newOrden) => {
    localStorage.setItem("_token", JSON.stringify(token));

    try {
      const payload = {
        loader: t("context_loadingCrearOrden"),
        url: apiCrearOrden,
        body: newOrden,
      };

      const response = await PostRequest(payload);
      console.log("🚀 ~ crearOrden ~ response:", response);
      if (response.HttpCode === 200) {
        setOrden(response.Result);
        localStorage.setItem("_orden", JSON.stringify(response.Result));
        //Obtener los parametros de configuracion...
        if (
          response.Result.OpcionPago === 1 ||
          response.Result.OpcionPago === 2
        ) {
          await getParametros(response.Result.OpcionPago);
        }
      } else {
        handleErrorMessage(response.Message);
        navigate("/" + routeError);
      }
    } catch (error) {
      handleErrorMessage(error);
      navigate("/" + routeError);
    }
  };

  const getParametros = async (iOpcionPago) => {
    const _url = apiParametrosObtener.replace("{iOpcionPago}", iOpcionPago);

    const payload = {
      url: _url,
    };

    const response = await GetRequest(payload);
    if (response.HttpCode === 200) {
      let _parametros = getModelConfig();
      let claves = Object.keys(_parametros);

      for (let i = 0; i < claves.length; i++) {
        let clave = claves[i];
        _parametros[clave] = response.Result[clave];
      }

      setParametros(_parametros);
    }
  };

  const registrarPagoPayPal = async (orden, datos) => {
    try {
      const body = {
        IdOrden: orden.IdOrden,
        IdReferencia: datos.purchase_units[0].reference_id,
        IdPagador: datos.payer.payer_id,
        NombreCompleto:
          datos.payer.name.given_name + " " + datos.payer.name.surname,
        Correo: datos.payer.email_address,
        Estatus: datos.status,
        FechaPago: datos.create_time,
        IdPago: datos.purchase_units[0].payments.captures[0].id,
      };

      const payload = {
        loader: t("context_loadingProcesandoPago"),
        url: apiRegistrarPagoPaypal,
        body: body,
      };

      const response = await PostRequest(payload);
      if (!response.HasError) {
        navigate("/" + routeSuccess);
      } else {
        handleErrorMessage(response.Message);
        navigate("/" + routeError);
      }
    } catch {
      handleErrorMessage("Error al procesar el pago.");
      navigate("/" + routeError);
    }
  };

  const registrarPagoMercadoPago = async (orden, datos) => {
    try {
      const body = {
        IdOrden: orden.IdOrden,
        IdPago: datos.payment_id,
        Estatus: datos.status,
        ReferenciaExterna: datos.external_reference,
        TipoPago: datos.payment_type,
        IdOrdenComercial: datos.merchant_order_id,
        IdSitio: datos.site_id,
        ModoProcesamiento: datos.processing_mode,
        IdCuentaComercial: datos.merchant_account_id,
      };

      const payload = {
        loader: t("context_loadingProcesandoPago"),
        url: apiRegistrarPagoMercadoPago,
        body: body,
      };

      const response = await PostRequest(payload);
      if (!response.HasError) {
        navigate("/" + routeSuccess);
      } else {
        handleErrorMessage(response.Message);
        navigate("/" + routeError);
      }
    } catch {
      handleErrorMessage("Error al procesar el pago.");
      navigate("/" + routeError);
    }
  };

  const registrarPagoBanorte = async (datos) => {
    try {
      const body = {
        IdOrden: orden.IdOrden,
        ChypherData: datos.data,
      };

      const payload = {
        loader: t("context_loadingProcesandoPago"),
        url: apiRegistrarPagoBanorte,
        body: body,
      };

      const response = await PostRequest(payload);

      if (!response.HasError) {
        navigate("/" + routeSuccess);
      } else {
        handleErrorMessage(response.Message);
        navigate("/" + routeError);
      }
    } catch {
      handleErrorMessage("Error al procesar el pago.");
      navigate("/" + routeError);
    }
  };

  const formatMoneda = (monto, moneda = false) => {
    let MXPesos = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
    });

    let result = MXPesos.format(monto);
    if (moneda) result = result + " MXN";
    return result;
  };

  const getFechaActual = () => {
    var _date = null;
    let date = new Date();
    if (_language === "en") {
      _date =
        date.toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
        }) +
        ", " +
        date.toLocaleDateString("en-US", {
          year: "numeric",
        });
    } else {
      _date =
        date.toLocaleDateString("es-MX", {
          month: "long",
          day: "numeric",
        }) +
        ", " +
        date.toLocaleDateString("es-MX", {
          year: "numeric",
        });
    }

    return _date;
  };

  const handleClickFinalizar = async (accion) => {
    const response = accion === "SUCCESS" ? 1 : 2;

    if (
      window.AndroidNative &&
      typeof window.AndroidNative.responsePay === "function"
    ) {
      window.AndroidNative.responsePay(JSON.stringify(response));
    } else if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.messageHandler
    ) {
      window.webkit.messageHandlers.messageHandler.postMessage(
        JSON.stringify(response)
      );
    } else {
      console.log("web");
    }
  };

  const handleClickBanorte = () => {
    window.fnCheckoutBanorte(orden.DataEncripted);
  };

  const handleRedirect = async (opcion, response) => {
    switch (opcion) {
      case "onClosed":
        handleErrorMessage(t("context_onCloseBanorte"));
        navigate("/" + routeError);
        break;
      case "onError":
        handleErrorMessage(t("context_onErrorBanorte"));
        navigate("/" + routeError);
        break;
      case "onCancel":
        handleErrorMessage(t("context_onCancelBanorte"));
        navigate("/" + routeError);
        break;
      case "onSuccess":
        if (response.status3D === "200") {
          //registrar el pago
          await registrarPagoBanorte(response);
        } else {
          handleErrorMessage(response.message);
          navigate("/" + routeError);
        }
        break;
      case "error":
        handleErrorMessage(t("context_onErrorBanorte"));
        navigate("/" + routeError);
        break;
    }
  };

  return (
    <PagosContext.Provider
      value={{
        orden,
        parametros,
        errorMessage,
        getOrden,
        handleAlert,
        registrarPagoPayPal,
        registrarPagoMercadoPago,
        formatMoneda,
        getFechaActual,
        handleErrorMessage,
        handleClickFinalizar,
        handleClickBanorte,
        crearOrden,
        handleRedirect,
        sumarItems,
      }}
    >
      {props.children}
    </PagosContext.Provider>
  );
};

export const usePagos = () => useContext(PagosContext);
