import { createContext, useContext } from "react";
import axios from "axios";
import { getResponseModel } from "../../model/responseModel";
import { useLayout } from "./LayoutContext";
import { useTranslation } from "react-i18next";

export const RequestContext = createContext();

export const RequestProvider = (props) => {
  const { i18n, t } = useTranslation();
  const _language = i18n.language; 
  const { handleOpenAlert, handleOpenLoader, handleCloseLoader } = useLayout();

  const errorMessage = "Ocurrió un error al consumir el recurso solicitado";
  const errorForbiden =
    "No cuenta con los permisos necesarios para consumir este  recurso";
  const errorNotFound = "No se encontró el recurso solicitado";

  const PostRequest = async (payload) => {
    let response = getResponseModel();

    if (payload.loader) {
      handleOpenLoader(payload.loader);
    }

    try {
      await axios
        .post(payload.url, payload.body, getToken())
        .then((resp) => {
          response = resp.data;
        })
        .catch((error) => {
          if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            if (error.response.status === 401) {
              response.HasError = true;
              response.HttpCode = 401;
              response.Message = "El token del usuario ha caducado.";
            } else {
              response.HasError = error.response.data.HasError;
              response.HttpCode = error.response.data.HttpCode;
              response.Message = error.response.data.Message;
            }
          } else {
            if (error.request) {
              console.log(error.request);
            }
            response.HasError = true;
            response.HttpCode = 500;
            response.Message = errorMessage;
          }
        });
    } catch (error) {
      response.HasError = true;
      response.HttpCode = 500;
      response.Message = errorMessage;
    } finally {
      handleCloseLoader();
    }

    return response;
  };

  const GetRequest = async (payload) => {
    let response = getResponseModel();

    if (payload.loader) {
      handleOpenLoader(payload.loader);
    }

    try {
      await axios
        .get(payload.url, getToken())
        .then((resp) => {
          response = resp.data;
        })
        .catch((error) => {
          if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            if (error.response.status === 401) {
              response.HasError = true;
              response.HttpCode = 401;
              response.Message = "El token del usuario ha caducado.";
            } else {
              response.HasError = error.response.data.HasError;
              response.HttpCode = error.response.data.HttpCode;
              response.Message = error.response.data.Message;
            }
          } else {
            response.HasError = true;
            response.HttpCode = 500;
            response.Message = errorMessage;
          }
        });
    } catch (error) {
      response.HasError = true;
      response.HttpCode = 500;
      response.Message = errorMessage;

      handleOpenAlert(response.Message);
    } finally {
      handleCloseLoader();
    }

    return response;
  };

  const getToken = () => {
    const token = localStorage.getItem("_token");
    const config = {
      headers: { 
        'Authorization': `Bearer ${token.replace(/['"]+/g, "")}`,
        'Accept-Language': `${_language}`
      },
    };

    return config;
  };

  return (
    <RequestContext.Provider
      value={{
        GetRequest,
        PostRequest,
      }}
    >
      {props.children}
    </RequestContext.Provider>
  );
};

export const useRequest = () => useContext(RequestContext);
